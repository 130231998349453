<script setup lang="ts">
import { feedbackIntegration } from '@sentry/vue'
import { useI18n, useModal } from '#imports'
import { LocaleSwitcherModal } from '#components'
import { getLocaleOptionFromCode } from '~/utils/locale'

const modal = useModal()
const { $t, $getLocale } = useI18n()

const isOpen = defineModel<boolean>()

async function onReportBug() {
  const feedback = feedbackIntegration({
    autoInject: false,
  })

  const form = await feedback.createForm()

  // Get the sidebar out of the way for a screenshot
  isOpen.value = false

  form.appendToDom()
  form.open()
}

function onChangeLanguage() {
  modal.open(LocaleSwitcherModal)
}
</script>

<template>
  <UDashboardSlideover
    v-model="isOpen"
    :title="$t('helpAndSupport')"
  >
    <UDashboardCard
      :title="$t('reportABug')"
      :description="$t('somethingDoesntLookRight')"
      :links="[{ label: $t('reportBug'), color: 'gray', leadingIcon: 'i-ion-bug-outline' }]"
      @click="onReportBug()"
    />

    <UDashboardCard
      class="mt-6"
      :title="$t('changeLanguage')"
      @click="onChangeLanguage()"
    >
      <template #links>
        <UButton
          :label="getLocaleOptionFromCode($getLocale())?.label"
          :leadingIcon="getLocaleOptionFromCode($getLocale())?.icon"
          variant="solid"
          color="gray"
        ></UButton>
      </template>
    </UDashboardCard>
  </UDashboardSlideover>
</template>
