import { setLocale } from 'yup'

setLocale({
  mixed: {
    default: () => ({ key: 'formValidation.invalid', context: {} }),
    required: () => ({ message: 'test', key: 'formValidation.required', context: {} }),
    notNull: () => ({ key: 'formValidation.notNull', context: {} }),
    defined: () => ({ key: 'formValidation.defined', context: {} }),
    oneOf: ({ values }) => ({ key: 'formValidation.oneOf', context: { values } }),
    notType: ({ type }) => ({ key: 'formValidation.notType', context: { type } }),
  },
  string: {
    email: () => ({ key: 'formValidation.email', context: {} }),
    url: () => ({ key: 'formValidation.url', context: {} }),
    min: ({ min }) => ({ key: 'formValidation.min', context: { min } }),
    max: ({ max }) => ({ key: 'formValidation.max', context: { max } }),
    length: ({ length }) => ({ key: 'formValidation.max', context: { length } }),
    uuid: () => ({ key: 'formValidation.uuid', context: {} }),
    matches: () => ({ key: 'formValidation.matches', context: {} }),
  },
  number: {
    min: ({ min }) => ({ key: 'formValidation.minValue', context: { min } }),
    max: ({ max }) => ({ key: 'formValidation.maxValue', context: { max } }),
  },
  array: {
    min: ({ min }) => ({ key: 'formValidation.minItems', context: { min } }),
    max: ({ max }) => ({ key: 'formValidation.maxItems', context: { max } }),
  },
})

export const setLocaleConfig = () => {
  // These keys map to app/locales/en-GB.json
  // List of all available keys: https://github.com/jquense/yup/blob/master/src/locale.ts

  console.log('locale config set')
}
