import type { TenantSelect } from '~~/server/database/schema'

const activeTenant = ref<TenantSelect | null>(null)

export function useTenant() {
  // const appConfig = useAppConfig()

  function setActiveTenant(tenant: TenantSelect) {
    activeTenant.value = tenant

    // appConfig.ui.primary = tenant.primaryBrandColor
  }

  return { tenantId: activeTenant.value?.id, activeTenant, setActiveTenant }
}
