import { useNuxtApp } from "#imports";
export function useI18n() {
  const nuxtApp = useNuxtApp();
  const injections = {
    $defaultLocale: nuxtApp.$defaultLocale,
    $getLocale: nuxtApp.$getLocale,
    $getLocaleName: nuxtApp.$getLocaleName,
    $getLocales: nuxtApp.$getLocales,
    $getRouteName: nuxtApp.$getRouteName,
    $t: nuxtApp.$t,
    $ts: nuxtApp.$ts,
    $tn: nuxtApp.$tn,
    $td: nuxtApp.$td,
    $has: nuxtApp.$has,
    $tc: nuxtApp.$tc,
    $mergeTranslations: nuxtApp.$mergeTranslations,
    $setI18nRouteParams: nuxtApp.$setI18nRouteParams,
    $switchLocaleRoute: nuxtApp.$switchLocaleRoute,
    $switchLocalePath: nuxtApp.$switchLocalePath,
    $switchLocale: nuxtApp.$switchLocale,
    $switchRoute: nuxtApp.$switchRoute,
    $localeRoute: nuxtApp.$localeRoute,
    $localePath: nuxtApp.$localePath
  };
  const noDollarInjections = Object.fromEntries(
    Object.entries(injections).map(([key, value]) => [key.slice(1), value])
  );
  return {
    ...injections,
    ...noDollarInjections
  };
}
