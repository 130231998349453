import { getPlatform, isNativePlatform } from '~/utils/native-app/capacitor'
import { appVersion } from '~~/shared/version'

export default defineNuxtPlugin({
  name: 'sentry',
  dependsOn: ['device'],
  parallel: true,
  setup(nuxtApp) {
    if (import.meta.env.TEST) {
      return
    }

    const {
      public: { errorReportingEnabled, sentryConfig },
    } = useRuntimeConfig()

    let userProxy = {
      id: undefined,
      organisationId: undefined,
      email: undefined,
      username: undefined,
      roles: undefined,
    }

    let capturedException: any

    let Sentry: any = {
      hasLoaded: false,
      setUser: (user: typeof userProxy) => {
        console.log('Sentry Proxy set user', user)
        userProxy = { ...userProxy, ...user }
      },
      captureException: (error: any) => {
        console.error('Sentry Proxy captureException', error)
        capturedException = error

        if (Sentry.hasLoaded === false) {
          loadSentry()
        }
      },
    }

    async function loadSentry() {
      const LazySentry = await import('@sentry/vue')

      Sentry = LazySentry

      console.log('Sentry loaded')

      if (!sentryConfig.client.dsn) {
        console.error('Sentry DSN not found in environment')
        return
      }

      Sentry.init({
        enabled: errorReportingEnabled,
        app: nuxtApp.vueApp,
        release: appVersion,
        dsn: sentryConfig.client.dsn,
        debug: false,
        environment: sentryConfig.environment,
        ignoreErrors: [/Network Error/i, /Fetch Error/i],
      })

      if (!errorReportingEnabled) {
        return false
      }

      Sentry.setTag('app-platform', getPlatform())
      Sentry.setTag('app-native', isNativePlatform())

      if (userProxy.id) {
        Sentry.setUser(userProxy)
      }

      if (capturedException) {
        Sentry.captureException(capturedException)
      }
    }

    nuxtApp.hook('page:finish', async () => {
      if (import.meta.client && Sentry.hasLoaded === false) {
        // Sleep for 1 second
        await new Promise(resolve => setTimeout(resolve, 1500))

        if (Sentry.hasLoaded === false) {
          loadSentry()
        } else {
          console.log('Sentry already loaded')
        }
      }
    })

    return {
      provide: {
        sentry: Sentry,
      },
    }
  },
})
