interface TenantConfig {
  name: string
  id: string
  country: string
  currency: string
  currencySymbol: string
  language: string
  timezone: string
  mobileNumber: {
    countryCode: string
    maxLength: number
  }
  address: {
    postcode: {
      maxLength: number
    }
  }
}

// TODO: Move this all to db
export const tenantConfig: Record<string, TenantConfig> = {
  UK: {
    id: 'UK',
    country: 'United Kingdom',
    name: 'Logoipsum',
    currency: 'GBP',
    currencySymbol: '£',
    language: 'en-GB',
    timezone: 'Europe/London',
    mobileNumber: {
      countryCode: 'GB',
      maxLength: 11,
    },
    address: {
      postcode: {
        maxLength: 8,
      },
    },
  },
}
