import type { FetchResponse } from 'ofetch'
import { isJwtExpiringInNext5Minutes } from '~~/layers/auth/app/utils/jwt'

export function hasAccessTokenExpired(accessToken: string | null, refreshToken: string | null) {
  if (
    (accessToken && isJwtExpiringInNext5Minutes(accessToken) && refreshToken)
    || (refreshToken && !accessToken)
  ) {
    return true
  } else if (!refreshToken) {
    return true
  } else {
    return false
  }
}

export async function extractTokensFromResponse(response: FetchResponse<any>, setTokens: any) {
  const accessToken = response._data?.accessToken
  const refreshToken = response._data?.refreshToken

  if (accessToken && refreshToken) {
    // $log.debug('Auth: Access token set from response')
    await setTokens(accessToken, refreshToken)
  }

  return { accessToken, refreshToken }
}
