// When our app first runs on the client, we want to load the user's auth from cookies or keychain values.
import { useToken } from '~~/layers/auth/app/composables/use-token'

export default defineNuxtPlugin({
  name: 'client-init',
  dependsOn: ['logger'],
  setup() {
    const authStore = useAuthStore()
    const { loadKeychainValues } = useToken()

    authStore.loadCookies()

    // If that fails let's try load keychain values for native apps
    // as sometimes the OS deletes the app cookies
    if (!authStore.getAccessToken()) {
      loadKeychainValues()
    }
  },
})
