import { Capacitor } from '@capacitor/core'
import type { FetchResponse } from 'ofetch'
import type { AuthForgotPassword } from '~~/layers/auth/shared/models/forgot-password.model'
import type { AuthLogin } from '~~/layers/auth/shared/models/login.model'
import type { Profile } from '~~/layers/auth/shared/models/profile.model'
import type { AuthResetPassword } from '~~/layers/auth/shared/models/reset-password.model'
import type { AuthUpdatePassword } from '~~/layers/auth/shared/models/update-password.model'
import type { AuthRefreshTokenSelect, UserSelect, UserWithOrganisationRoles } from '~~/server/database/schema'
import { appVersion } from '~~/shared/version'
import { isNativePlatform } from '~/utils/native-app/capacitor'
import { getPayloadFromAccessToken } from '~~/layers/auth/app/utils/jwt'
import { useNuxtApp } from '#app/nuxt'
import { useTenant } from '~/composables/use-tenant'
import { useApi } from '~/composables/api/use-api'
import { useAuthStore } from '~~/layers/auth/app/stores/auth'

export function useAuthService() {
  const { $log, $config } = useNuxtApp()
  const authStore = useAuthStore()
  const { tenantId } = useTenant()
  const { setItemInKeychain, getItemInKeychain, removeItemInKeychain } = useKeychain()
  const { isBiometricsAvailable, performBiometricVerification } = useBiometrics()
  const { setTokens, clearTokens } = useToken()

  const { apiStatus, api } = useApi()

  async function register(formData: Profile): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof register>>('/auth/v1/register', {
      method: 'POST',
      body: formData,
    })

    $log.event('Auth: registered')

    await setTokens(accessToken, refreshToken)

    return {
      accessToken,
      refreshToken,
      user,
    }
  }

  function extractOrganisationFromToken(accessToken: string | null) {
    if (!accessToken) {
      return false
    }

    // Extract organisationId from JWT
    const payload = getPayloadFromAccessToken(accessToken)

    if (payload?.org) {
      authStore.setCurrentOrganisationId(payload.org)
    }

    if (payload?.imp) {
      authStore.setImpersonating(true)
    }
  }

  async function isBiometricAuthActive() {
    if (!isNativePlatform()) {
      return false
    }

    const isAvailable = await isBiometricsAvailable()

    if (!isAvailable) {
      return false
    }

    return !!(await getItemInKeychain('biometricsEnabled'))
  }

  async function setBiometricsStatus(isEnabled: boolean) {
    if (isEnabled) {
      await setItemInKeychain('biometricsEnabled', Date.now().toString())
    } else {
      await removeItemInKeychain('biometricsEnabled')
    }
  }

  async function loginWithPassword(formData: AuthLogin): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof loginWithPassword>>(
      '/auth/v1/login/password',
      {
        method: 'POST',
        body: formData,
      },
    )

    $log.event('Auth: logged in')

    await setTokens(accessToken, refreshToken)

    await fetchProfile()

    // If we previously logged in with an oauth provider, clear it
    authStore.setLastUsedOauthProvider(null)

    // Save username and password in keychain for biometric auth
    await setItemInKeychain('username', formData.email)
    await setItemInKeychain('password', formData.password)

    console.log('6')
    return {
      accessToken,
      refreshToken,
      user,
    }
  }

  async function loginWithBiometrics() {
    const verificationSuccessfull = await performBiometricVerification()

    if (!verificationSuccessfull) {
      throw new Error('Biometric verification failed')
    }

    const email = await getItemInKeychain('username')
    const password = await getItemInKeychain('password')

    await loginWithPassword({
      email: email as string,
      password: password as string,
    })

    return true
  }

  async function loginWithOauthProvider(
    provider: string,
    providerAccessToken: string,
  ): Promise<{
      accessToken: string
      refreshToken: string
      user: UserSelect
    }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof loginWithOauthProvider>>(
      '/auth/v1/login/oauth',
      {
        method: 'POST',
        body: {
          provider,
          providerAccessToken,
        },
      },
    )

    $log.event('Auth: logged in with oauth provider', provider)

    await setTokens(accessToken, refreshToken)

    await fetchProfile()

    return {
      accessToken,
      refreshToken,
      user,
    }
  }

  async function logout(returnToPath?: string) {
    const refreshToken = authStore.getRefreshToken()

    $log.event('Auth: logged out')

    // Revoke our refresh token from the db
    if (refreshToken) {
      api('/auth/v1/logout', {
        method: 'POST',
        body: {
          refreshToken: authStore.getRefreshToken(),
        },
      })
    }

    clearTokens()

    $log.clearIdentity()

    let url = '/auth/login'

    if (returnToPath && !returnToPath.startsWith('/auth')) {
      // Remove any existing redirect query params
      const cleanReturnToPath = returnToPath.replace(/\?.*$/, '')

      url += `?redirect=${cleanReturnToPath}`
    }

    await navigateTo(url)
  }

  async function forgotPassword(formData: AuthForgotPassword) {
    $log.event('Auth: forgot password')

    return await api('/auth/v1/forgot-password', {
      method: 'POST',
      body: formData,
    })
  }

  async function fetchProfile() {
    try {
      const user: UserWithOrganisationRoles = await api('/profile/v1', {
        method: 'GET',
      })

      if (!user?.id) {
        throw new Error('No user found in profile response')
      }

      authStore.setCurrentUser(user)

      extractOrganisationFromToken(authStore.getAccessToken())

      $log.setIdentity(user)

      return user
    } catch (error) {
      $log.error('Failed to fetch profile', error)

      logout()

      return null
    }
  }

  async function updateProfile(profile: Profile) {
    try {
      const user: UserWithOrganisationRoles = await api('/profile/v1', {
        method: 'PUT',
        body: profile,
      })

      await fetchProfile()

      return user
    } catch (error) {
      $log.error('Failed to update profile', error)

      return null
    }
  }

  async function updatePassword(formData: AuthUpdatePassword) {
    return await api('/auth/v1/password', {
      method: 'PUT',
      body: formData,
    })
  }

  async function refreshTokens() {
    const refreshToken = authStore.getRefreshToken()

    if (refreshToken) {
      $log.debug('Auth: Refreshing token')

      await $fetch('/auth/v1/refresh', {
        baseURL: $config.public.platformApiBaseUrl,
        method: 'POST',
        body: {
          refreshToken,
        },
        headers: {
          'Tenant-Id': tenantId,
          'Guest-Id': $log.getGuestId()!,
          'App-Platform': Capacitor.getPlatform(),
          'App-Version': appVersion,
        },

        onResponse({
          response,
        }: {
          response: FetchResponse<{
            accessToken: string
            refreshToken: string
          }>
        }) {
          extractTokensFromResponse(response, setTokens)
        },

        async onResponseError({ response }: { response: FetchResponse<any> }) {
          if (response.status === 401) {
            $log.debug('Auth: Failed to refresh token')

            // Failed to refresh token, logout and redirect to login page
            await clearTokens()
          }
        },
      })
    }
  }

  async function resetPassword(formData: AuthResetPassword) {
    const { accessToken, refreshToken } = await api<{
      accessToken: string
      refreshToken: string
    }>('/auth/v1/reset-password', {
      method: 'POST',
      body: formData,
    })

    $log.event('Auth: set new password')

    await setTokens(accessToken, refreshToken)

    return {
      accessToken,
      refreshToken,
    }
  }

  async function validateResetPasswordCode(verificationCode: string): Promise<{
    isValid: boolean
  }> {
    try {
      return await api(`/auth/v1/reset-password/${verificationCode}`)
    } catch (_error) {
      return {
        isValid: false,
      }
    }
  }

  async function impersonateUser(userId: string): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof impersonateUser>>(
      '/auth/v1/impersonate',
      {
        method: 'POST',
        body: {
          userId,
        },
      },
    )

    $log.event('Auth: impersonated user', userId)

    // Keep our admin refresh token so we can easily switch back to admin user
    authStore.preserveAdminRefreshToken()

    await setTokens(accessToken, refreshToken)

    await fetchProfile()

    return {
      accessToken,
      refreshToken,
      user,
    }
  }

  async function returnFromImpersonation() {
    $log.event('Auth: returned from impersonation')

    // Clear the impersonation tokens
    clearTokens()

    // Restore the admin refresh token
    authStore.restoreAdminRefreshToken()

    await fetchProfile()

    navigateTo(authStore.accountPath)
  }

  async function removeOauth(authId: string) {
    const removeOauthResponse = await api(`/auth/v1/login/${authId}`, {
      method: 'DELETE',
    })

    await fetchProfile()

    return removeOauthResponse
  }

  async function fetchActiveLogins(): Promise<AuthRefreshTokenSelect[]> {
    return await api('/auth/v1/login', {
      method: 'GET',
    })
  }

  async function logoutSessionId(refreshTokenId: string) {
    return await api('/auth/v1/refresh', {
      method: 'DELETE',
      body: {
        refreshTokenId,
      },
    })
  }

  async function redirectAfterLogin(fullPath: string) {
    await nextTick()

    const route = new URL(fullPath, 'http://localhost')

    // Get query params
    const queryParams = Object.fromEntries(route.searchParams.entries())

    console.log('queryParams.redirect', queryParams.redirect)

    if (queryParams.redirect) {
      $log.debug('Login: Redirecting to previous route', queryParams.redirect)

      await navigateTo(queryParams.redirect as string)
      return
    }

    await navigateTo(authStore.accountPath)
  }

  return {
    apiStatus,
    fetchProfile,
    register,
    loginWithPassword,
    loginWithBiometrics,
    loginWithOauthProvider,
    logout,
    forgotPassword,
    refreshTokens,
    resetPassword,
    validateResetPasswordCode,
    impersonateUser,
    returnFromImpersonation,
    updateProfile,
    updatePassword,
    removeOauth,
    fetchActiveLogins,
    logoutSessionId,
    redirectAfterLogin,
    isBiometricAuthActive,
    extractOrganisationFromToken,
    setBiometricsStatus,
  }
}
