export const localeOptions = [
  {
    label: 'English (UK)',
    value: 'en-GB',
    icon: 'i-cif-gb',
  },
  {
    label: 'Deutsch',
    value: 'de-DE',
    icon: 'i-cif-de',
  },
  {
    label: 'Français',
    value: 'fr-FR',
    icon: 'i-cif-fr',
  },
]

export function getLocaleOptionFromCode(code: string) {
  return localeOptions.find(option => option.value === code)
}
