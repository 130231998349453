let platform = 'web'
let operatingSystem = 'windows'

export async function loadCapacitor() {
  if (import.meta.client) {
    console.log('Core capacitor init')

    const { Device } = await import('@capacitor/device')

    const deviceInfo = await Device.getInfo()

    platform = deviceInfo.platform
    operatingSystem = deviceInfo.operatingSystem
  }
}

export function isNativePlatform() {
  return platform !== 'web'
}

export function getPlatform() {
  return platform
}

export function isIos() {
  return operatingSystem === 'ios'
}

export function isAndroid() {
  return operatingSystem === 'android'
}

export function getOperatingSystem() {
  return operatingSystem
}

export function addStatusTapListener() {
  const { $log } = useNuxtApp()

  if (import.meta.client) {
    window.addEventListener('statusTap', function () {
      $log.debug('Native: Status tap event')
      window.scrollTo(0, 0)
    })
  }
}

export async function hideSplashScreen(hideAfterMs = 0) {
  const { $log } = useNuxtApp()

  if (isNativePlatform()) {
    const { SplashScreen } = await import('@capacitor/splash-screen')

    if (hideAfterMs > 0) {
      await new Promise(resolve => setTimeout(resolve, hideAfterMs))
    }

    $log.debug('Native: Hiding splash screen')

    SplashScreen.hide()
  }
}

export async function setStatusBarTheme(theme: 'auth' | 'splash-screen' | 'platform') {
  if (isNativePlatform()) {
    console.log({ theme })
    // const { StatusBar, StatusBarStyle } = await import('@capacitor/status-bar')
  }
}
