import { Log, STORAGE_KEY_GUEST_ID } from '@/utils/log'

export default defineNuxtPlugin({
  name: 'logger',
  setup() {
    const { $sentry, $posthog } = useNuxtApp()
    const router = useRouter()

    const {
      public: { errorReportingEnabled, analyticsReportingEnabled },
    } = useRuntimeConfig()

    const logger = new Log({
      saveByDefault: true,
      errorReportingEnabled,
      analyticsReportingEnabled,
    })

    // Get the previous guest ID from the cookie
    const guestCookie = useCookie(STORAGE_KEY_GUEST_ID, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    })

    logger.setGuestCookie(guestCookie)
    logger.addSentry($sentry)
    logger.addAnalytics($posthog)

    router.afterEach((to) => {
      logger.debug('New page',
        to.fullPath)
    })

    if (import.meta.client && !import.meta.env.TEST) {
      setInterval(() => {
        processLogs()
      }, 5000)

      // When the user is closing the page, flush out our logs queue
      window.addEventListener('beforeunload', async function () {
        await processLogs()
      })
    }

    async function processLogs() {
      const { sendLogs } = useLogService()

      if (logger.list.length === 0) {
        return false
      }

      const maxBatchSize = 15

      // Process all logs in the queue and remove them
      const batch = [...logger.list]

      const limitedBatch = batch.slice(0, maxBatchSize)

      logger.list = []

      try {
        await sendLogs(limitedBatch)
      } catch (error) {
        // Can't $log.debug here as we could create an inifite loop of sending failed logs
        console.error('Logs: Error sending analytics logs', error)

        // Re-add failed logs back into the queue
        logger.list = limitedBatch.concat(logger.list)
      } finally {
        // If there were more logs left in the batch, add them back to the queue
        if (batch.length > maxBatchSize) {
          logger.list = batch.slice(maxBatchSize).concat(logger.list)
        }
      }
    }

    return {
      provide: {
        log: logger,
      },
    }
  },
})
