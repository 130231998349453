<script setup lang="ts">
import { useColorMode } from '@vueuse/core'
import { Toaster } from 'vue-sonner'
import { useBreakpoints } from './composables/use-breakpoint'
import {
  useI18n, useHead, useSeoMeta, onMounted, ref, computed,
} from '#imports'
import { useRuntimeConfig } from '#app/nuxt'

const { activeBreakpoint } = useBreakpoints()
const { $t } = useI18n()
const {
  public: { appEnvironment },
} = useRuntimeConfig()

const colorMode = useColorMode()

const color = computed(() => (colorMode.value === 'dark' ? '#111827' : 'white'))

useHead({
  htmlAttrs: { lang: 'en' },
  link: [{ rel: 'icon', href: '/icon.png' }],
  meta: [
    {
      name: 'viewport',
      content: 'viewport-fit=cover, width=device-width, initial-scale=1.0, user-scalable=no',
    },
    { key: 'theme-color', name: 'theme-color', content: color },
    appEnvironment !== 'production'
      ? {
          name: 'robots',
          content: 'noindex',
        }
      : {},
  ],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Saas` : 'Saas'
  },
})

useSeoMeta({
  description: 'SaaS meta description',
  ogImage: '/oauth-image.png',
  twitterImage: '/oauth-image.png',
  twitterCard: 'summary_large_image',
})

// Setting activeBreakpoint as a class on the layout element will cause hydration issues with SSR
// So we only add the class once the component is mounted on the client side
const isMounted = ref(false)

onMounted(() => {
  if (import.meta.client) {
    isMounted.value = true
  }
})
</script>

<template>
  <div :class="isMounted ? activeBreakpoint : undefined">
    <NuxtLoadingIndicator />

    <NuxtErrorBoundary>
      <template #error="{ error }">
        <p>{{ $t('anErrorOccured') }} {{ error }}</p>
      </template>
    </NuxtErrorBoundary>

    <NuxtPage />

    <ClientOnly>
      <Toaster
        position="top-center"
        richColors
      />
    </ClientOnly>

    <UModals />
  </div>
</template>
