import { isNativePlatform } from '~/utils/native-app/capacitor'
import { tenantConfig } from '~/utils/tenants/config'

export default defineNuxtRouteMiddleware(async () => {
  const { $log } = useNuxtApp()

  const { activeTenant, setActiveTenant } = useTenant()
  const { fetchTenantByCurrentHostname } = useTenantService()

  console.log('isNativePlatform', isNativePlatform())

  if (!activeTenant.value) {
    if (isNativePlatform()) {
      $log.debug('Native platform, skipping tenant fetch')

      console.log('config', tenantConfig.UK)

      setActiveTenant(tenantConfig.UK)
    } else {
      $log.debug('No tenant yet, fetching from API')

      try {
        const tenantWithDomain = await fetchTenantByCurrentHostname()

        setActiveTenant(tenantWithDomain.tenant)
      } catch (error: any) {
        $log.error('Error fetching tenant', error)

        if (error.statusCode === 404) {
          throw createError({
            statusCode: 500,
            statusMessage: 'The current domain is not associated with this platform',
          })
        } else {
          return navigateTo('/maintenance')
        }
      }
    }
  }

  console.log('middleware tenant', activeTenant.value?.id)
})
