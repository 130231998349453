import { SecureStorage } from '@aparajita/capacitor-secure-storage'
import { isNativePlatform } from '~/utils/native-app/capacitor'

export function useKeychain() {
  async function setItemInKeychain(key: string, value: string) {
    if (!isNativePlatform()) {
      return false
    }

    await SecureStorage.set(key, value)
  }

  async function getItemInKeychain(key: string) {
    if (!isNativePlatform()) {
      return null
    }

    return await SecureStorage.get(key)
  }

  async function removeItemInKeychain(key: string) {
    if (!isNativePlatform()) {
      return false
    }

    await SecureStorage.remove(key)

    return true
  }

  return { setItemInKeychain, getItemInKeychain, removeItemInKeychain }
}
