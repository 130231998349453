export function useToken() {
  const { $log } = useNuxtApp()
  const { setItemInKeychain, getItemInKeychain, removeItemInKeychain } = useKeychain()
  const authStore = useAuthStore()

  async function setTokens(accessToken: string, refreshToken: string) {
    authStore.setAccessToken(accessToken)
    authStore.setRefreshToken(refreshToken)

    // Persist refresh token in keychain for native apps
    await setItemInKeychain('accessToken', accessToken)
    await setItemInKeychain('refreshToken', refreshToken)
  }

  async function clearTokens() {
    authStore.clearTokens()
    await removeItemInKeychain('accessToken')
    await removeItemInKeychain('refreshToken')
  }

  async function loadKeychainValues() {
    $log.debug('Auth: Loading keychain values')

    const accessToken = await getItemInKeychain('accessToken')

    if (accessToken) {
      authStore.setAccessToken(accessToken as string)
    }

    const refreshToken = await getItemInKeychain('refreshToken')

    if (refreshToken) {
      authStore.setRefreshToken(refreshToken as string)
    }
  }

  return { setTokens, clearTokens, loadKeychainValues }
}
