import { getPlatform, isNativePlatform } from '~/utils/native-app/capacitor'
import { appVersion } from '~~/shared/version'

export default defineNuxtPlugin({
  name: 'analytics',
  dependsOn: ['device'],
  setup(nuxtApp) {
    if (import.meta.env.TEST) {
      return
    }

    const {
      public: { analyticsReportingEnabled, posthogConfig },
    } = useRuntimeConfig()

    const config = useRuntimeConfig()

    let userProxy = {
      userId: '',
      email: undefined,
      roles: undefined,
    }

    const eventProxy = []

    let posthog: any = {
      hasLoaded: false,
      identify: (userId: string, user: typeof userProxy) => {
        userProxy = {
          ...userProxy,
          ...user,
          userId,
        }
      },
      capture: (event: string, data: any) => {
        eventProxy.push({ event, data })
      },
    }

    async function loadPosthog() {
      const { posthog: LazyPosthog } = await import('posthog-js')

      posthog = LazyPosthog

      console.log('Posthog loaded')

      const router = useRouter()

      posthog.init(posthogConfig.dsn, {
        capture_pageview: false,
        capture_pageleave: true,
        api_host: posthogConfig.apiHost,
        disable_session_recording: true,
        advanced_disable_decide: true,
        advanced_disable_feature_flags: true,
        advanced_disable_feature_flags_on_first_load: true,
        enable_recording_console_log: false,
        autocapture: false,
      })

      posthog.register({
        environment: config.public.appEnvironment,
        countryCode: config.public.appCountryCode,
        release: appVersion,
        platform: getPlatform(),
        native: isNativePlatform(),
      })

      if (userProxy.userId) {
        console.log('Posthog proxy identify', userProxy)

        posthog.identify(userProxy.userId, {
          email: userProxy.email,
          roles: userProxy.roles,
        })
      }

      router.afterEach((to) => {
        posthog.capture('$pageview', {
          $current_url: to.fullPath,
        })
      })

      window.addEventListener('beforeunload', () => {
        posthog.capture(
          '$pageleave',
          {
            from_url: window.location.href,
          },
          { send_instantly: true },
        )
      })
    }

    nuxtApp.hook('page:finish', async () => {
      if (import.meta.client && analyticsReportingEnabled && posthog.hasLoaded === false) {
        // Sleep for 1 second
        await new Promise(resolve => setTimeout(resolve, 1500))

        if (posthog.hasLoaded === false) {
          loadPosthog()
        } else {
          console.log('Posthog already loaded')
        }
      }
    })

    return {
      provide: {
        analytics: posthog,
      },
    }
  },
})
