import { useAuthService } from '~~/layers/auth/app/composables/services/use-auth-service'
import { hasAccessTokenExpired } from '~~/layers/auth/app/utils/lifespan'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $log } = useNuxtApp()
  const authStore = useAuthStore()
  const {
    fetchProfile, extractOrganisationFromToken, refreshTokens, logout,
  } = useAuthService()

  const accessToken = authStore.getAccessToken()
  const refreshToken = authStore.getRefreshToken()

  const accessTokenHasExpired = hasAccessTokenExpired(accessToken, refreshToken)

  if (accessTokenHasExpired) {
    try {
      await refreshTokens()
    } catch (_error) {
      $log.debug('Failed to refresh tokens in global middleware, logging out')
      return logout(to.fullPath)
    }
  }

  // console.log('authStore.isLoggedIn', authStore.isLoggedIn)
  // console.log('authStore.currentUser?.id', authStore.currentUser?.id)
  // console.log('to.path', to.fullPath)

  if (authStore.isLoggedIn && !authStore.currentUser?.id && !to.path.startsWith('/auth')) {
    try {
      await fetchProfile()

      if (!authStore.currentOrganisation) {
        extractOrganisationFromToken(authStore.getAccessToken())
      }
    } catch (_error) {
      return navigateTo(`/auth/login?redirect=${to.path}`)
    }
  }

  // Lost guest user
  if (
    (to.path.startsWith('/admin') || to.path.startsWith('/staff') || to.path.startsWith('/platform'))
    && !authStore.isLoggedIn
  ) {
    console.log('Lost guest user')
    return navigateTo(`/auth/login?redirect=${to.path}`)
  }

  // Lost staff member
  if (authStore.isStaffOrganisation && (to.path.startsWith('/admin') || to.path.startsWith('/platform'))) {
    console.log('Lost staff member')
    return navigateTo(authStore.accountPath)
  }

  // Lost app admin
  if (authStore.isTenantOrganisation && (to.path.startsWith('/staff') || to.path.startsWith('/platform'))) {
    console.log('Lost app admin')
    return navigateTo(authStore.accountPath)
  }

  // Lost customer
  if (
    !authStore.isTenantOrganisation
    && !authStore.isStaffOrganisation
    && (to.path.startsWith('/staff') || to.path.startsWith('/admin'))
  ) {
    console.log('Lost customer')
    return navigateTo(authStore.accountPath)
  }
})
