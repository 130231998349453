import { generateAccessTokenLifespan, generateRefreshTokenLifespan } from '~~/layers/auth/app/utils/jwt'
import type {
  OrganisationSelect,
  UserOrganisationRoleWithOrganisation,
  UserWithOrganisationRoles,
} from '~~/server/database/schema'
import { cookieEnums } from '~~/shared/constants'

export const useAuthStore = defineStore('auth', () => {
  // const {
  //   public: { mediaBaseUrl },
  // } = useRuntimeConfig()

  const mediaBaseUrl = 'https://media.henrycrooks.com'

  const accessToken = ref<string | null>(null)
  const refreshToken = ref<string | null>(null)
  const lastUsedOauthProvider = ref<string | null>(null)
  const adminPreservedRefreshToken = ref<string | null>(null)
  const isImpersonatingUser = ref<boolean>(false)
  const currentUser = ref<UserWithOrganisationRoles | null>(null)

  const isLoggedIn = computed(() => !!(refreshToken.value || accessToken.value))

  const currentOrganisation = computed<OrganisationSelect | null>(() => {
    return currentOrganisationRole.value?.organisation || null
  })

  const currentOrganisationRole = computed<UserOrganisationRoleWithOrganisation | null>(() => {
    if (!currentUser.value?.organisationRoles?.length || !currentOrganisationCookie.value) {
      return null
    }

    return currentUser.value.organisationRoles.find(
      orgRole => orgRole.organisationId === currentOrganisationCookie.value,
    )
  })

  const userHasPasswordAuth = computed(() => {
    return !!currentUser.value?.authenticationMethods?.some(authMethod => authMethod.provider === 'password')
  })

  const avatarUrl = computed(() => {
    return currentUser.value?.avatar?.path ? `${mediaBaseUrl}/${currentUser.value.avatar.path}` : undefined
  })

  const isStaffOrganisation = computed(() => {
    return currentOrganisation.value?.isStaff === true
  })

  const isTenantOrganisation = computed(() => {
    return currentOrganisation.value?.isTenant === true
  })

  const accountPath = computed(() => {
    if (isStaffOrganisation.value) {
      return '/staff'
    }

    if (isTenantOrganisation.value) {
      return '/admin'
    }

    return '/platform'
  })

  function setCurrentUser(user: UserWithOrganisationRoles) {
    currentUser.value = user
  }

  const accessTokenCookie = useCookie(cookieEnums.ACCESS_TOKEN, {
    path: '/',
    maxAge: generateAccessTokenLifespan().seconds,
    expires: generateAccessTokenLifespan().date,
    secure: true,
  })

  const refreshTokenCookie = useCookie(cookieEnums.REFRESH_TOKEN, {
    path: '/',
    maxAge: generateRefreshTokenLifespan().seconds,
    expires: generateRefreshTokenLifespan().date,
    secure: true,
  })

  // Save last used oauth cookie for 1 year
  const lastUsedOauthProviderCookie = useCookie(cookieEnums.LAST_USED_SOCIAL_PROVIDER, {
    path: '/',
    maxAge: 31536000,
    secure: true,
  })

  function loadCookies() {
    console.log('Auth store: Loading cookies')

    if (accessTokenCookie.value) {
      setAccessToken(accessTokenCookie.value)
    }

    if (refreshTokenCookie.value) {
      setRefreshToken(refreshTokenCookie.value)
    }

    if (currentOrganisationCookie.value) {
      setCurrentOrganisationId(currentOrganisationCookie.value)
    }

    if (lastUsedOauthProviderCookie.value) {
      setLastUsedOauthProvider(lastUsedOauthProviderCookie.value)
    }
  }

  function getAccessToken() {
    return accessToken.value
  }

  function setAccessToken(token: string) {
    if (!token) {
      return
    }

    accessToken.value = token
    accessTokenCookie.value = token
  }

  function getRefreshToken() {
    return refreshToken.value
  }

  function setRefreshToken(token: string) {
    if (!token) {
      return
    }

    refreshToken.value = token
    refreshTokenCookie.value = token
  }

  function setLastUsedOauthProvider(provider: string | null) {
    lastUsedOauthProvider.value = provider
    lastUsedOauthProviderCookie.value = provider
  }

  const currentOrganisationCookie = useCookie(cookieEnums.ORGANISATION, {
    path: '/',
    maxAge: generateRefreshTokenLifespan().seconds,
    expires: generateRefreshTokenLifespan().date,
    secure: true,
  })

  function setCurrentOrganisationId(organisationId: string) {
    console.log('setting current organisation', organisationId)
    currentOrganisationCookie.value = organisationId
  }

  // When impersonating we want a quick way to switch back to the admin user
  function preserveAdminRefreshToken() {
    if (!isTenantOrganisation.value && !isStaffOrganisation.value) {
      return
    }

    adminPreservedRefreshToken.value = refreshToken.value
  }

  function restoreAdminRefreshToken() {
    if (adminPreservedRefreshToken.value) {
      setRefreshToken(adminPreservedRefreshToken.value)

      adminPreservedRefreshToken.value = null
    }
  }

  function setImpersonating(isImpersonating: boolean) {
    isImpersonatingUser.value = isImpersonating
  }

  function clearTokens() {
    console.log('CLEARING TOKENS')
    accessToken.value = null
    refreshToken.value = null

    // Delete token cookies
    accessTokenCookie.value = null
    refreshTokenCookie.value = null
    currentOrganisationCookie.value = null
  }

  return {
    lastUsedOauthProvider,
    currentUser,
    setCurrentUser,
    clearTokens,
    userHasPasswordAuth,
    isLoggedIn,
    avatarUrl,
    isStaffOrganisation,
    isTenantOrganisation,
    isImpersonatingUser,
    accountPath,
    setAccessToken,
    getAccessToken,
    setRefreshToken,
    getRefreshToken,
    setCurrentOrganisationId,
    setLastUsedOauthProvider,
    currentOrganisation,
    currentOrganisationRole,
    preserveAdminRefreshToken,
    setImpersonating,
    restoreAdminRefreshToken,
    loadCookies,
  }
})
